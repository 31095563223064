import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'

function AppStoreLogo(props: SvgIconProps) {
  return (
    <SvgIcon
      height="52"
      style={{ height: 'auto', width: 'auto' }}
      viewBox="0 0 52 52"
      width="52"
      {...props}
    >
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_705_1063"
          x1="25.9996"
          x2="25.9996"
          y1="0.316711"
          y2="51.6835"
        >
          <stop stopColor="#17C9FB" />
          <stop offset="1" stopColor="#1A74E8" />
        </linearGradient>
      </defs>
      <path
        d="M11.5655 0.316711H40.4337C46.6465 0.316711 51.683 5.35319 51.683 11.566V40.4342C51.683 46.647 46.6465 51.6835 40.4337 51.6835H11.5655C5.35269 51.6835 0.316208 46.647 0.316208 40.4342V11.566C0.316208 5.35319 5.35269 0.316711 11.5655 0.316711Z"
        fill="url(#paint0_linear_705_1063)"
      />
      <path
        d="M16.778 37.5999L16.7829 37.6014L15.0266 40.6433C14.3856 41.7536 12.9657 42.1341 11.8553 41.493C10.745 40.852 10.3646 39.4321 11.0056 38.3217L12.2994 36.0809L12.4235 35.8658C12.6453 35.547 13.1925 34.9971 14.287 35.1005C14.287 35.1005 16.8627 35.38 17.0491 36.7187C17.0491 36.7187 17.0746 37.1593 16.778 37.5999ZM41.6876 29.8304H36.211C35.8381 29.8055 35.6751 29.6723 35.6112 29.5949L35.6072 29.5878L29.7446 19.4336L29.737 19.4387L29.3854 18.9345C28.8092 18.0534 27.8942 20.3071 27.8942 20.3071C26.8017 22.8184 28.0492 25.6736 28.4842 26.5365L36.6269 40.6401C37.2679 41.7505 38.6878 42.1309 39.7982 41.4898C40.9085 40.8488 41.289 39.4289 40.6479 38.3186L38.6117 34.7919C38.5723 34.7065 38.5036 34.4744 38.9212 34.4735H41.6876C42.9697 34.4735 44.0091 33.4341 44.0091 32.152C44.0091 30.8698 42.9697 29.8304 41.6876 29.8304ZM31.0503 32.9865C31.0503 32.9865 31.3426 34.4734 30.2115 34.4734H29.0804V34.4735H9.96596C8.68382 34.4735 7.64442 33.4341 7.64442 32.152C7.64442 30.8698 8.68382 29.8304 9.96596 29.8304H15.1709C16.0112 29.7817 16.2104 29.2966 16.2104 29.2966L16.215 29.299L23.0091 17.5312L23.0071 17.5307C23.131 17.3034 23.0278 17.0886 23.0099 17.0546L20.7661 13.1683C20.125 12.058 20.5055 10.6381 21.6159 9.99709C22.7262 9.356 24.146 9.73646 24.7871 10.8468L25.8277 12.6493L26.8665 10.85C27.5075 9.73966 28.9274 9.3592 30.0378 10.0003C31.1481 10.6414 31.5286 12.0612 30.8875 13.1716L21.4332 29.5467C21.3919 29.6464 21.3793 29.8029 21.6868 29.8304H27.338L27.3392 29.8855C27.3392 29.8855 30.6054 29.9363 31.0503 32.9865Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export default AppStoreLogo
