import { defineMessages } from 'react-intl'

const messages = {
  contactSupport: {
    defaultMessage: 'contact our support team.',
    id: 'page.login.contactSupport',
  },
  investInSSContent: {
    defaultMessage:
      'Our mission is to empower retail and foodservice businesses with scalable, regulatory compliant and data-driven workforce management. We would be happy if you wanted to share your opinion with others',
    id: 'page.login.investInSS.content',
  },
  investInSSTitle: {
    defaultMessage: 'Would you like to recommend SameSystem to others?',
    id: 'page.login.investInSS.title',
  },
  signInWithMicrosoft: {
    defaultMessage: 'Sign in with Microsoft',
    id: 'page.login.signInWithMicrosoft',
  },
  signInWithMicrosoftDisclaimer: {
    defaultMessage:
      'Single Sign-On (SSO) must be enabled by your employer for this option to work. Please contact your employer if you encounter any issues.',
    id: 'page.login.signInWithMicrosoftDisclaimer',
  },
  troubleLoggingIn: {
    defaultMessage: 'If you are having trouble logging in please',
    id: 'page.login.troubleLoggingIn',
  },
}

export default defineMessages(messages)
