import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'

function LoginPageIcon(props: SvgIconProps) {
  return (
    <SvgIcon height="190" viewBox="0 0 180 190" width="180" {...props}>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_705_975"
          x1="92.0113"
          x2="92.0113"
          y1="28.3081"
          y2="183.192"
        >
          <stop stopColor="white" stopOpacity="0.24" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_705_975"
          x1="83.3523"
          x2="106.162"
          y1="0.778492"
          y2="70.5719"
        >
          <stop stopColor="#E1E6F5" />
          <stop offset="1" stopColor="#BCC5E5" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_705_975"
          x1="24.3735"
          x2="39.0404"
          y1="30.1967"
          y2="75.0751"
        >
          <stop stopColor="#E1E6F5" />
          <stop offset="1" stopColor="#BCC5E5" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_705_975"
          x1="148.38"
          x2="163.047"
          y1="30.1967"
          y2="75.0751"
        >
          <stop stopColor="#E1E6F5" />
          <stop offset="1" stopColor="#BCC5E5" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint4_linear_705_975"
          x1="55.1688"
          x2="84.9067"
          y1="172.208"
          y2="173.339"
        >
          <stop stopColor="#B8C2E5" />
          <stop offset="1" stopColor="#9EAAD8" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint5_linear_705_975"
          x1="39.5137"
          x2="81.7454"
          y1="96.5837"
          y2="178.993"
        >
          <stop stopColor="#DFE4F4" />
          <stop offset="1" stopColor="#C5CEEA" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint6_linear_705_975"
          x1="35.9229"
          x2="49.4237"
          y1="128.233"
          y2="93.2586"
        >
          <stop stopColor="#C4CCE9" />
          <stop offset="1" stopColor="#B9C3E4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint7_linear_705_975"
          x1="48.9867"
          x2="62.2962"
          y1="134.818"
          y2="100.339"
        >
          <stop stopColor="#C4CCE9" />
          <stop offset="1" stopColor="#B9C3E4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint8_linear_705_975"
          x1="62.4041"
          x2="75.2228"
          y1="142.876"
          y2="109.672"
        >
          <stop stopColor="#C4CCE9" />
          <stop offset="1" stopColor="#B9C3E4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint9_linear_705_975"
          x1="72.582"
          x2="84.8018"
          y1="152.451"
          y2="120.801"
        >
          <stop stopColor="#C4CCE9" />
          <stop offset="1" stopColor="#B9C3E4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint10_linear_705_975"
          x1="78.4289"
          x2="86.739"
          y1="161.247"
          y2="139.737"
        >
          <stop stopColor="#C4CCE9" />
          <stop offset="1" stopColor="#B9C3E4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint11_linear_705_975"
          x1="163.143"
          x2="176.056"
          y1="79.1503"
          y2="78.2871"
        >
          <stop stopColor="#B8C2E5" />
          <stop offset="1" stopColor="#9EAAD8" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint12_linear_705_975"
          x1="138.095"
          x2="126.266"
          y1="90.0026"
          y2="144.454"
        >
          <stop stopColor="#E4E8F6" />
          <stop offset="1" stopColor="#ADB8E0" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint13_linear_705_975"
          x1="132.554"
          x2="124.817"
          y1="94.3425"
          y2="131.881"
        >
          <stop stopColor="#EEF1FA" />
          <stop offset="1" stopColor="#D5DBF1" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint14_linear_705_975"
          x1="136.108"
          x2="128.371"
          y1="100.783"
          y2="138.321"
        >
          <stop stopColor="#EEF1FA" />
          <stop offset="1" stopColor="#D5DBF1" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint15_linear_705_975"
          x1="139.661"
          x2="131.923"
          y1="107.224"
          y2="144.763"
        >
          <stop stopColor="#EEF1FA" />
          <stop offset="1" stopColor="#D5DBF1" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint16_linear_705_975"
          x1="115.978"
          x2="95.5887"
          y1="129.83"
          y2="141.352"
        >
          <stop stopColor="#EEF1FA" />
          <stop offset="1" stopColor="#D5DBF1" />
        </linearGradient>
        <clipPath id="clip0_705_975">
          <rect fill="white" height="190" width="180" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip0_705_975)">
        <path
          d="M92.0113 183.192C134.533 183.192 169.003 148.52 169.003 105.75C169.003 62.98 134.533 28.3081 92.0113 28.3081C49.4899 28.3081 15.0196 62.98 15.0196 105.75C15.0196 148.52 49.4899 183.192 92.0113 183.192Z"
          fill="url(#paint0_linear_705_975)"
        />
        <path
          d="M92.7972 0.608699L103.238 21.8907C103.399 22.2137 103.704 22.4374 104.058 22.4912L127.406 25.9036C128.3 26.0361 128.658 27.1377 128.012 27.7713L111.115 44.3364C110.859 44.589 110.74 44.9493 110.802 45.3055L114.791 68.6954C114.944 69.5899 114.009 70.2732 113.21 69.8508L92.3278 58.806C92.0108 58.6362 91.632 58.6362 91.315 58.806L70.4325 69.8508C69.6338 70.2732 68.6992 69.5899 68.8515 68.6954L72.8411 45.3055C72.9028 44.9493 72.7834 44.589 72.5282 44.3364L55.6312 27.7713C54.9848 27.1377 55.343 26.032 56.2364 25.9036L79.5851 22.4912C79.9391 22.4374 80.2479 22.2137 80.4044 21.8907L90.8456 0.608699C91.245 -0.207132 92.3978 -0.207132 92.7972 0.608699Z"
          fill="url(#paint1_linear_705_975)"
        />
        <path
          d="M30.4464 30.089L37.1615 43.7718C37.2645 43.9788 37.4621 44.1238 37.6885 44.1569L52.6999 46.3518C53.2721 46.4346 53.5027 47.1469 53.0869 47.5527L42.2216 58.2041C42.0569 58.3656 41.9828 58.5975 42.0198 58.8294L44.5848 73.8705C44.6837 74.4462 44.0825 74.8851 43.5679 74.6118L30.1417 67.5095C29.9358 67.4019 29.6929 67.4019 29.4912 67.5095L16.065 74.6118C15.5503 74.8851 14.9492 74.4462 15.048 73.8705L17.613 58.8294C17.6501 58.6017 17.576 58.3697 17.4113 58.2041L6.546 47.5527C6.13017 47.1469 6.36073 46.4346 6.93302 46.3518L21.9443 44.1569C22.1708 44.1238 22.3684 43.9788 22.4713 43.7718L29.1865 30.089C29.4418 29.5672 30.187 29.5672 30.4422 30.089H30.4464Z"
          fill="url(#paint2_linear_705_975)"
        />
        <path
          d="M154.452 30.089L161.168 43.7718C161.271 43.9788 161.468 44.1238 161.695 44.1569L176.706 46.3518C177.278 46.4346 177.509 47.1469 177.093 47.5527L166.228 58.2041C166.063 58.3656 165.989 58.5975 166.026 58.8294L168.591 73.8705C168.69 74.4462 168.089 74.8851 167.574 74.6118L154.148 67.5095C153.942 67.4019 153.699 67.4019 153.497 67.5095L140.071 74.6118C139.556 74.8851 138.955 74.4462 139.054 73.8705L141.619 58.8294C141.656 58.6017 141.582 58.3697 141.417 58.2041L130.552 47.5527C130.136 47.1469 130.367 46.4346 130.939 46.3518L145.95 44.1569C146.177 44.1238 146.375 43.9788 146.477 43.7718L153.193 30.089C153.448 29.5672 154.193 29.5672 154.448 30.089H154.452Z"
          fill="url(#paint3_linear_705_975)"
        />
        <path
          d="M68.2218 188.534C57.7764 194.17 51.0366 182.099 57.093 176.839C63.3552 171.402 85.5717 155.88 85.5717 155.88C85.5717 155.88 73.8418 176.611 72.586 180.827C71.3303 185.043 68.2218 188.534 68.2218 188.534Z"
          fill="url(#paint4_linear_705_975)"
        />
        <path
          d="M0 122.994C34.461 132.469 60.6752 160.858 67.6374 174.168C72.7798 183.999 68.3497 188.455 68.3497 188.455C68.3497 188.455 102.378 164.448 111.901 153.346C114.858 149.896 115.644 143.407 114.018 139.638C105.005 118.737 92.0648 108.955 81.603 101.923C58.9995 86.7332 51.0533 93.5539 46.1044 95.815C41.1556 98.0762 0 122.994 0 122.994Z"
          fill="url(#paint5_linear_705_975)"
        />
        <path
          d="M24.0777 122.108L61.1325 99.3309"
          stroke="url(#paint6_linear_705_975)"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="3.5"
        />
        <path
          d="M37.2527 128.734L73.8958 106.371"
          stroke="url(#paint7_linear_705_975)"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="3.5"
        />
        <path
          d="M51.2506 137.017L86.2468 115.482"
          stroke="url(#paint8_linear_705_975)"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="3.5"
        />
        <path
          d="M62.3672 146.956L94.8931 126.249"
          stroke="url(#paint9_linear_705_975)"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="3.5"
        />
        <path
          d="M72.249 157.309L92.835 143.643"
          stroke="url(#paint10_linear_705_975)"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="3.5"
        />
        <path
          d="M174.554 91.8458L179.13 100.137C181.003 103.53 179.785 107.815 176.412 109.698L169.384 113.618L157.951 93.0986L165.046 89.1078C168.419 87.2241 172.679 88.4496 174.552 91.8422L174.554 91.8458Z"
          fill="url(#paint11_linear_705_975)"
        />
        <path
          d="M170.672 112.907L159.306 92.3162L109.041 120.388L120.407 140.978L170.672 112.907Z"
          fill="url(#paint12_linear_705_975)"
        />
        <path
          d="M158.827 97.8809C159.491 99.083 159.059 100.603 157.863 101.271L112.401 126.66L109.994 122.301L155.457 96.9115C156.652 96.2441 158.164 96.6789 158.827 97.8809Z"
          fill="url(#paint13_linear_705_975)"
        />
        <path
          d="M162.375 104.31C163.039 105.512 162.607 107.033 161.412 107.7L115.949 133.09L113.542 128.73L159.005 103.341C160.2 102.673 161.712 103.108 162.375 104.31Z"
          fill="url(#paint14_linear_705_975)"
        />
        <path
          d="M165.925 110.747C166.589 111.949 166.156 113.47 164.961 114.137L119.499 139.527L117.092 135.167L162.555 109.778C163.75 109.11 165.262 109.545 165.925 110.747Z"
          fill="url(#paint15_linear_705_975)"
        />
        <path
          d="M109.028 120.381L95.6673 140.856C95.3667 141.315 95.7085 141.928 96.256 141.903L120.391 140.972C118.308 133.157 114.52 126.295 109.028 120.381Z"
          fill="url(#paint16_linear_705_975)"
        />
        <path
          d="M104.387 141.589L96.2639 141.903C95.7163 141.924 95.3746 141.315 95.6752 140.851L100.175 133.952C102.876 135.811 104.313 138.342 104.391 141.589H104.387Z"
          fill="#9EAAD8"
        />
      </g>
    </SvgIcon>
  )
}

export default LoginPageIcon
